import { ContextState, UserAction } from '@customTypes/context';

import { AMPLITUDE_EXPERIMENT_CONTROL_NAME } from '@constants/amplitudeExperiments';

import actionTypes from './actionTypes';

const reducer = (oldState: ContextState, action: UserAction) => {
  let newState: ContextState = {
    numberOfCartItems: 0,
    popupValues: {
      message: 'Something went wrong. Please try again.',
      isWarning: false,
      isOpen: false,
      redirect: undefined,
    },
    onlyShowAvailableHotels: false,
    searchDate: undefined,
    selectedCurrency: {
      name: 'United States Dollar',
      symbol: '$',
      iso_code: 'USD',
    },
    defaultCurrency: {
      name: 'United States Dollar',
      symbol: '$',
      iso_code: 'USD',
    },
    windowUrl: null,
    srpData: null,
    srpScrollPosition: null,
    isDatePickerOpen: false,
    seoCityPage: '',
    isFiltersExpanded: false,
    totalFilterCount: 0,
    showPastDateSelectedError: false,
    userCityAndState: '',
    smartCalendarProductFilter: null,
    selectedTimeSlot: undefined,
    guestsGroupBag: [],
    referer: null,
    searchResults: null,
    timeSlotGuestBag: {
      productId: 0,
      adultsCount: 0,
      childrenCount: 0,
    },
    showSameDayBookingWarning: false,
    searchFilters: null,
    availableSearchFilterOptions: null,
    searchedLocation: null,
    showCookiesConsentBanner: false,
    acceptCookies: false,
    userLocation: null,
    pricingMarkdownExperiment: AMPLITUDE_EXPERIMENT_CONTROL_NAME,
    bfCMPromoFlag: null,
    fixedDateSelectorExperiment: null,
  };
  const { payload } = action;
  switch (action.type) {
    case actionTypes.UPDATE_NUMBER_OF_CART_ITEMS:
      newState = { ...oldState, numberOfCartItems: payload };
      return newState;

    case actionTypes.TOGGLE_ERROR_POPUP:
      newState = {
        ...oldState,
        popupValues: {
          isOpen: payload.isOpen,
          message: payload.message,
          isWarning: payload.isWarning ?? false,
          redirect: payload.redirect ?? undefined,
        },
      };
      return newState;

    case actionTypes.TOGGLE_ONLY_SHOW_AVAILABLE_HOTELS:
      newState = { ...oldState, onlyShowAvailableHotels: payload };
      return newState;

    case actionTypes.SET_SEARCH_DATE:
      newState = { ...oldState, searchDate: payload };
      return newState;

    case actionTypes.UPDATE_SELECTED_CURRENCY:
      newState = {
        ...oldState,
        selectedCurrency: payload,
      };
      return newState;

    case actionTypes.UPDATE_DEFAULT_CURRENCY:
      newState = {
        ...oldState,
        defaultCurrency: payload,
      };
      return newState;

    case actionTypes.UPDATE_WINDOW_URL:
      newState = {
        ...oldState,
        windowUrl: payload,
      };
      return newState;

    case actionTypes.UPDATE_SRP_DATA:
      newState = {
        ...oldState,
        srpData: { ...payload },
      };
      return newState;

    case actionTypes.UPDATE_SRP_SCROLL_POSITION:
      newState = {
        ...oldState,
        srpScrollPosition: payload,
      };
      return newState;

    case actionTypes.OPEN_DATE_PICKER:
      newState = {
        ...oldState,
        isDatePickerOpen: payload,
      };
      return newState;

    case actionTypes.CITY_SEO_PAGE:
      newState = {
        ...oldState,
        seoCityPage: payload,
      };
      return newState;
    case actionTypes.OPEN_FILTERS:
      newState = {
        ...oldState,
        isFiltersExpanded: payload,
      };
      return newState;

    case actionTypes.UPDATE_FILTER_COUNT:
      newState = {
        ...oldState,
        totalFilterCount: payload,
      };
      return newState;

    case actionTypes.SHOW_PAST_DATE_SELECTED_ERROR:
      newState = {
        ...oldState,
        showPastDateSelectedError: payload,
      };
      return newState;

    case actionTypes.USER_CITY_AND_STATE:
      newState = {
        ...oldState,
        userCityAndState: payload,
      };
      return newState;

    case actionTypes.SET_SMART_CALENDAR_PRODUCT_FILTER:
      newState = {
        ...oldState,
        smartCalendarProductFilter: payload,
      };
      return newState;

    case actionTypes.SET_GUEST_GROUPS_BAG:
      newState = {
        ...oldState,
        guestsGroupBag: payload,
      };
      return newState;

    case actionTypes.SET_SELECTED_TIME_SLOT:
      newState = {
        ...oldState,
        selectedTimeSlot: payload,
      };
      return newState;

    case actionTypes.SET_TIME_SLOT_GUEST_BAG:
      newState = {
        ...oldState,
        timeSlotGuestBag: payload,
      };
      return newState;

    case actionTypes.UPDATE_REFERER:
      newState = {
        ...oldState,
        referer: payload,
      };
      return newState;

    case actionTypes.UPDATE_SEARCH_RESULTS:
      newState = {
        ...oldState,
        searchResults: payload,
      };
      return newState;

    case actionTypes.OPEN_SAME_DAY_BOOKING_WARNING:
      newState = {
        ...oldState,
        showSameDayBookingWarning: payload,
      };
      return newState;

    case actionTypes.APPLY_SEARCH_FILTER:
      newState = {
        ...oldState,
        searchFilters: payload,
      };
      return newState;

    case actionTypes.AVAILABLE_SEARCH_FILTER_OPTIONS:
      newState = {
        ...oldState,
        availableSearchFilterOptions: payload,
      };
      return newState;

    case actionTypes.UPDATE_SEARCHED_LOCATION:
      newState = {
        ...oldState,
        searchedLocation: payload,
      };
      return newState;

    case actionTypes.UPDATE_SHOW_COOKIE_CONSENT_BANNER:
      newState = {
        ...oldState,
        showCookiesConsentBanner: payload,
      };
      return newState;

    case actionTypes.UPDATE_ACCEPT_COOKIES:
      newState = {
        ...oldState,
        acceptCookies: payload,
      };
      return newState;

    case actionTypes.SET_USER_LOCATION:
      newState = {
        ...oldState,
        userLocation: payload,
      };
      return newState;

    case actionTypes.SET_PRICING_MARKDOWN_EXPERIMENT:
      newState = {
        ...oldState,
        pricingMarkdownExperiment: payload,
      };
      return newState;

    case actionTypes.SET_FIXED_DATE_SELECTOR_EXPERIMENT:
      newState = {
        ...oldState,
        fixedDateSelectorExperiment: payload,
      };
      return newState;

    case actionTypes.SET_BFCM_PROMO_FLAG:
      newState = {
        ...oldState,
        bfCMPromoFlag: payload,
      };
      return newState;

    default:
      return newState;
  }
};

export default reducer;
