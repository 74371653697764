import { useContext, useMemo } from 'react';
import { store } from '@context/store';
import { marketingContentMapping } from '@constants/marketing';

type MarketingContentMapping = typeof marketingContentMapping;
type ComponentName = keyof MarketingContentMapping['default'];
export type PromoConfig = MarketingContentMapping[keyof MarketingContentMapping][ComponentName];

type Props = {
  componentName: ComponentName;
};

export default function useEmailCaptureConfig({ componentName }: Props) {
  const { state } = useContext(store);
  const { bfCMPromoFlag } = state;

  const promoConfig = useMemo(() => {
    const stateKey = (bfCMPromoFlag || 'default') as keyof MarketingContentMapping;

    return marketingContentMapping[stateKey]?.[componentName] as PromoConfig;
  }, [bfCMPromoFlag, componentName]);

  return { bfCMPromoFlag, promoConfig };
}
