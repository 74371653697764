import { MarketingContentMapping } from '@customTypes/promos';

import { environment } from '@helpers/environment';

export const marketingEmailSignupId = environment.isProduction
  ? 'marketing-email-signup'
  : 'marketing-email-signup-staging';

export const marketingContentMapping: MarketingContentMapping = {
  default: {
    HomePageMarketingModule: {
      image: 'home-v2/marketing_module_v2.png',
      pre_submission_title: 'Save on the best part of your next vacation.',
      pre_submission_subtitle:
        'Sign up to get your exclusive code and save on your first experience.',
      pre_submission_button_label: 'Get Offer',
      post_submission_title: 'Confirmed',
      post_submission_subtitle:
        "You're on your way to recharge. Check your email for your exclusive discount.",
      already_submitted_title: 'The best part of your next vacation.',
      already_submitted_subtitle: 'Level up your vacation, poolside or at a luxury spa.',
      already_submitted_button_label: 'Explore',
    },

    CaptureEmailDLPInLine: {
      promo_text: 'Unlock exclusive savings and access to new hotels',
    },

    CaptureEmailFooter: {
      promo_text: 'Unlock access to exclusive savings',
      promo_text_cta_label: 'Enter Email',
    },

    CaptureEmailNavMenuItem: {},

    SkinnyBanner: {
      promo_text: 'Get Custom Offers',
      promo_text_cta_label: 'Enter Email',
    },

    SRPMarketingModule: {
      image: 'home-v2/marketing_module_v2.png',
      pre_submission_title: 'Save on the best part of your next vacation.',
      pre_submission_subtitle:
        'Sign up to get your exclusive code and save on your first experience.',
      pre_submission_button_label: 'Enter',
      post_submission_title: 'Confirmed',
      post_submission_subtitle:
        "You're on your way to recharge. Check your email for your exclusive discount.",
      already_submitted_title: 'The best part of your next vacation.',
      already_submitted_subtitle: 'Level up your vacation, poolside or at a luxury spa.',
    },
  },

  'early-access': {
    HomePageMarketingModule: {
      image: 'web_marketing_module_desktop.png',
      pre_submission_title: 'Get early access to our Black Friday deal.',
      pre_submission_subtitle: 'Sign up to get your exclusive code.',
      pre_submission_button_label: 'Get Offer',
      post_submission_title: 'Confirmed',
      post_submission_subtitle:
        'Check your email for your exclusive discount. Your new holiday traditions are on the horizon.',
      already_submitted_title: 'The best part of your next vacation.',
      already_submitted_subtitle: 'Level up your vacation, poolside or at a luxury spa.',
      already_submitted_button_label: 'Search Near You',
    },

    CaptureEmailDLPInLine: {
      promo_text: 'Unlock early access to our Black Friday deal',
    },

    CaptureEmailFooter: {
      promo_text: 'Black Friday savings start early',
      promo_text_cta_label: 'Get Code',
    },

    CaptureEmailNavMenuItem: {},

    SkinnyBanner: {
      promo_text: 'Black Friday Starts Early',
      promo_text_cta_label: 'Get Access',
    },

    SRPMarketingModule: {
      image: 'web_marketing_module_desktop.png',
      pre_submission_title: 'Get early access to our Black Friday deal.',
      pre_submission_subtitle: 'Sign up to get your exclusive code.',
      pre_submission_button_label: 'Get Offer',
      post_submission_title: 'Confirmed',
      post_submission_subtitle:
        'Check your email for your exclusive discount. Your new holiday traditions are on the horizon.',
      already_submitted_title: 'The best part of your next vacation.',
      already_submitted_subtitle: 'Level up your vacation, poolside or at a luxury spa.',
    },
  },

  'main-promo': {
    HomePageMarketingModule: {
      image: 'web_marketing_module_desktop.png',
      pre_submission_title: 'Save $50 this holiday season.',
      pre_submission_subtitle: 'Sign up to get your exclusive code.',
      pre_submission_button_label: 'Get Offer',
      post_submission_title: 'Confirmed',
      post_submission_subtitle:
        'Your new holiday traditions are on the horizon. Enjoy $50 off bookings $200+. Check your email for your exclusive code.',
      already_submitted_title: 'The best part of your next vacation.',
      already_submitted_subtitle: 'Level up your vacation with a luxury pool or spa day.',
      already_submitted_button_label: 'Search Near You',
    },

    CaptureEmailDLPInLine: {
      promo_text: 'Our biggest sale of the year is here',
    },

    CaptureEmailFooter: {
      promo_text: 'Unlock $50 off this holiday season',
      promo_text_cta_label: 'Get Code',
    },

    CaptureEmailNavMenuItem: {},

    SkinnyBanner: {
      promo_text: '$50 Off Holiday Savings',
      promo_text_cta_label: 'Get Access',
    },

    SRPMarketingModule: {
      image: 'web_marketing_module_desktop.png',
      pre_submission_title: 'Save $50 this holiday season.',
      pre_submission_subtitle: 'Sign up to get your exclusive code.',
      pre_submission_button_label: 'Get Offer',
      post_submission_title: 'Confirmed',
      post_submission_subtitle:
        'Your new holiday traditions are on the horizon. Enjoy $50 off bookings $200+. Check your email for your exclusive code.',
      already_submitted_title: 'The best part of your next vacation.',
      already_submitted_subtitle: 'Level up your vacation, poolside or at a luxury spa.',
    },
  },
};
