import sessionStorageKeys from '@constants/sessionStorageKeys';
import actionTypes from '@context/actionTypes';
import useSessionStorage from '@hooks/useSessionStorage';
import { getDateWithDashes } from './dateFormatter';

type IsWeekendProp = string | Date;

const { setItem } = useSessionStorage();

/*
    Does the given date fall on a weekend?
    Weekend = Friday, Saturday or Sunday.
    Param: date string: '05/27/2022' | Date object

   In case you only need to check for 'Saturday | Sunday' you can use isWeekend() from 'date-fns'. More details here: https://date-fns.org/v2.28.0/docs/isWeekend
*/
const isWeekend = (date: IsWeekendProp) => {
  let selectedDay;

  if (typeof date === 'string') {
    selectedDay = new Date(date).getDay();
  } else {
    selectedDay = date.getDay();
  }

  switch (selectedDay) {
    case 5: // friday
    case 6: // saturday
    case 0: // sunday
      return true;

    default:
      return false;
  }
};

const pastEscapedSearchDate = (unescapedSearchDate: Date, dispatch: Function) => {
  const today = new Date();
  if (unescapedSearchDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
    dispatch({
      type: actionTypes.SET_SEARCH_DATE,
      payload: today,
    });
    setItem(sessionStorageKeys.SMART_CALENDAR_DATE, getDateWithDashes(today));
    return today;
  }
  return unescapedSearchDate;
};

// eslint-disable-next-line import/prefer-default-export
export { isWeekend, pastEscapedSearchDate };
