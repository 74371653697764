import React from 'react';

export default function BannerMessage({
  message,
  cta,
  isFading,
  onClick,
}: {
  message: string;
  cta: { text: string | undefined; id: string };
  isFading: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={`font-rp-pn-semi-bold text-sm text-rp-surface-brand-daylight transition-opacity duration-500 ${
        isFading ? 'opacity-0' : 'opacity-100'
      }`}
    >
      {message}{' '}
      {cta && (
        <button
          type="button"
          onClick={onClick}
          className={`ml-2 cursor-pointer underline ${cta.id || ''}`}
        >
          {cta.text || 'Enter Email'}
        </button>
      )}
    </div>
  );
}
