import { store } from '@context/store';
import React, { useState, Dispatch, SetStateAction, useEffect, useContext, useMemo } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import useCurrentUser from '@hooks/useCurrentUser';
import {
  loginInClick,
  logOutClick,
  androidAppDownloadClick,
  iOSAppDownloadClick,
} from '@events/globals';
import useAuthForm from '@hooks/useAuthForm';
import useAuthentication from '@hooks/useAuthentication';
import HeaderLinksV2 from '@components/HeaderLinks/HeaderLinksV2';
import ButtonPrimary from '@components/common/ButtonPrimary';
import appleStoreLogo from '@assets/images/apple-store-logo.svg';
import googleStoreLogo from '@assets/images/google-store-logo.svg';
import Link from 'next/link';
import {
  ANDROID_GUEST_DOWNLOAD_LINK,
  IOS_GUEST_DOWNLOAD_LINK,
  IOS_HOTEL_DOWNLOAD_LINK,
} from '@constants/links';
import ButtonPrimaryTransparent from '@components/common/ButtonPrimaryTransparent';
import closeButton from '@assets/images/close-white.svg';
import CaptureEmailNavMenuItem from '@components/CaptureEmail/CaptureEmailNavMenuItem';

type Props = {
  showMobileMenu: boolean;
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>;
  openCurrencySelectorModal: () => void;
};
const linkStyle = 'font-rp-pn-regular text-xl text-white font-rp-pn-semi-bold';

export default function MobileMenuV2({
  showMobileMenu,
  setShowMobileMenu,
  openCurrencySelectorModal,
}: Props) {
  const currentUser = useCurrentUser();
  const globalState = useContext(store);
  const { state } = globalState;
  const { selectedCurrency } = state;

  const leftPanelStyle = `${
    showMobileMenu ? '' : '-translate-x-full'
  } fixed inset-y-0 left-0 h-screen w-full z-500 py-0.5 px-0.5 transform transition duration-300 ease-in-out bg-rp-primary-new`;

  const backgroundStyle = `${
    showMobileMenu ? 'opacity-70' : 'opacity-0 pointer-events-none'
  } fixed inset-y-0 z-200 left-0 w-full h-screen bg-black transform transition-all duration-300 ease-in-out`;

  const showMyAccountButton = useMemo(
    () => currentUser.map(() => true).valueOr(false),
    [currentUser],
  );

  const buttonText = useMemo(
    () => currentUser.map(() => 'Log Out').valueOr('Login'),
    [currentUser],
  );

  const [isGuestSelected, setIsGuestSelected] = useState<boolean>(true);
  const [isAndroid, setIsAndroid] = useState<boolean>(false);

  const session = useSession();
  const router = useRouter();

  const authForm = useAuthForm();
  const { logoutUser } = useAuthentication();

  const onLogInButtonClick = async () => {
    setShowMobileMenu(false);
    if (buttonText === 'Log Out') {
      logOutClick();
      await logoutUser();
    } else {
      loginInClick();
      authForm.showLogin();
    }
  };

  const onSignUpButtonClick = () => {
    setShowMobileMenu(false);
    loginInClick();
    authForm.showSignUp();
  };

  const onMyAccountButtonClick = () => {
    setShowMobileMenu(false);
    router.push('/users/bookings');
  };

  useEffect(() => {
    // Change in Session Redirect Behavior
    // https://developers.facebook.com/blog/post/552/
    if (window.location.hash === '#_=_') {
      window.location.hash = '';
    }
  }, [session]);

  useEffect(() => {
    const isDeviceAndroid = /(android)/i.test(navigator.userAgent);
    setIsAndroid(isDeviceAndroid);
  }, []);

  return (
    <div className="flex d:hidden">
      <nav className={leftPanelStyle}>
        <div className="flex flex-col px-7 overflow-hidden mt-5">
          <div className="flex relative w-full">
            <div className="absolute pt-1.5 -left-8px">
              <Image
                src={closeButton}
                alt="close"
                width={18}
                height={18}
                onClick={() => setShowMobileMenu(false)}
              />
            </div>

            <div className="flex flex-grow justify-center mx-10 text-sm">
              <button
                className={`text-white rounded-l-md w-full py-1 ${
                  isGuestSelected
                    ? 'bg-white text-rp-primary-new '
                    : 'border border-white bg-transparent'
                }`}
                type="button"
                onClick={() => setIsGuestSelected(true)}
              >
                Guests
              </button>

              <button
                className={`text-white rounded-r-md w-full py-1 ${
                  !isGuestSelected
                    ? 'bg-white text-rp-primary-new '
                    : 'border border-white bg-transparent'
                }`}
                type="button"
                onClick={() => setIsGuestSelected(false)}
              >
                Hotels
              </button>
            </div>
          </div>

          <div className="flex flex-col mt-16 space-y-8">
            <CaptureEmailNavMenuItem />

            {isGuestSelected ? (
              <button
                type="button"
                className={`text-left ${linkStyle}`}
                onClick={openCurrencySelectorModal}
              >
                Currency
                <span className="ml-3 bg-white text-rp-primary-black text-15 px-3 py-1 rounded-md">{`${selectedCurrency.symbol} ${selectedCurrency.iso_code}`}</span>
              </button>
            ) : null}

            {/* Navigation links */}
            <HeaderLinksV2
              linkStyle={linkStyle}
              setShowMenu={setShowMobileMenu}
              isGuestSelected={isGuestSelected}
            />
          </div>
        </div>

        <div className="absolute bottom-8 px-7 w-full">
          {/* Login and Register buttons */}
          <div className="my-5">
            {isGuestSelected ? (
              <div className="w-full">
                {showMyAccountButton && (
                  <div className="my-5">
                    <ButtonPrimaryTransparent
                      onClick={onMyAccountButtonClick}
                      classes="w-full h-12 text-base"
                    >
                      My Account
                    </ButtonPrimaryTransparent>
                  </div>
                )}

                {!showMyAccountButton ? (
                  <ButtonPrimaryTransparent
                    onClick={onSignUpButtonClick}
                    classes="w-full h-12 mb-3 text-base"
                  >
                    Sign Up
                  </ButtonPrimaryTransparent>
                ) : null}

                <ButtonPrimary
                  onClick={onLogInButtonClick}
                  classes="w-full h-12 mb-3 text-base text-rp-primary-new"
                >
                  {buttonText}
                </ButtonPrimary>
              </div>
            ) : (
              <div className="w-full">
                <Link href={`${process.env.NEXT_PUBLIC_RAILS_APP_URL}/hotel_users/sign_in`}>
                  <ButtonPrimary
                    onClick={() => null}
                    classes="w-full h-12 mb-3 text-base text-rp-primary-new"
                  >
                    Log In
                  </ButtonPrimary>
                </Link>
              </div>
            )}
          </div>

          <div className="justify-center flex space-x-2 w-full">
            {!isAndroid && (
              <div className="w-154px h-46 relative cursor-pointer">
                <Link href={isGuestSelected ? IOS_GUEST_DOWNLOAD_LINK : IOS_HOTEL_DOWNLOAD_LINK}>
                  <a
                    onClick={() => {
                      iOSAppDownloadClick();
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        iOSAppDownloadClick();
                      }
                    }}
                    target="_blank"
                    title="Open in a new window"
                    rel="noreferrer"
                    role="link"
                    tabIndex={0}
                  >
                    <Image src={appleStoreLogo} alt="App Store" layout="fill" />
                  </a>
                </Link>
              </div>
            )}

            {isGuestSelected && isAndroid ? (
              <div className="w-154px h-46 relative cursor-pointer">
                <Link href={ANDROID_GUEST_DOWNLOAD_LINK}>
                  <a
                    onClick={() => {
                      androidAppDownloadClick();
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        androidAppDownloadClick();
                      }
                    }}
                    target="_blank"
                    title="Open in a new window"
                    rel="noreferrer"
                    role="link"
                    tabIndex={0}
                  >
                    <Image src={googleStoreLogo} alt="Google Play" layout="fill" />
                  </a>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </nav>

      {/* Faded black background when mobile menu is open */}
      <div
        className={backgroundStyle}
        onClick={() => setShowMobileMenu(false)}
        onKeyDown={() => setShowMobileMenu(false)}
        role="button"
        tabIndex={0}
        aria-label="close menu"
      />
    </div>
  );
}
