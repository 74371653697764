import React, { useRef, useEffect, useState } from 'react';
import Image from 'next/image';
import closeIcon from '@assets/images/close-black.svg';
import useWindowSize from '@hooks/useWindowSize';
import ModalProps from './ModalTypes';

const defaultProps = {
  childrenClass: '',
  showCloseButton: true,
  desktopWidth: 'd:max-w-2xl',
  containerClasses: '',
  hideScrollBar: false,
  overlayClasses: '',
  containerzIndex: 'z-400',
};

export default function Modal({
  children,
  isOpen,
  closePopup,
  childrenClass,
  showCloseButton,
  desktopWidth,
  containerClasses,
  closeButtonClasses,
  hideScrollBar,
  overlayClasses,
  containerzIndex,
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  const { height: windowHeight } = useWindowSize();
  const [addScrollbar, setAddScrollbar] = useState<boolean>(false);

  useEffect(() => {
    if (hideScrollBar) {
      setAddScrollbar(false);
    } else if (modalRef.current && windowHeight && isOpen) {
      const modalHeight = modalRef.current.offsetHeight;
      if (modalHeight > windowHeight * 0.7) {
        setAddScrollbar(true);
      } else {
        setAddScrollbar(false);
      }
    }
  }, [modalRef, windowHeight, isOpen, hideScrollBar]);

  return isOpen ? (
    <>
      <div
        className={`fixed left-0 top-0 h-full w-full z-300 bg-rp-black-faded overflow-auto ${overlayClasses}`}
        onClick={closePopup}
        onKeyDown={() => closePopup}
        role="button"
        tabIndex={0}
        aria-label="Close"
      />
      <div
        ref={modalRef}
        className={`-translate-x-1/2 -translate-y-1/2 fixed bg-white flex flex-col items-center justify-between left-1/2 rounded-xl top-1/2 transform-gpu w-11/12 ${
          addScrollbar ? 'max-h-70%' : ''
        } ${desktopWidth} ${containerClasses} ${containerzIndex}`}
      >
        {showCloseButton && (
          <div className={`justify-end flex mt-5 px-8 w-full ${closeButtonClasses}`}>
            <button type="button" onClick={closePopup}>
              <Image src={closeIcon} width={15} height={15} alt="Close" />
            </button>
          </div>
        )}

        <div className={`${childrenClass} w-full ${addScrollbar ? 'overflow-y-scroll' : ''}`}>
          {children}
        </div>
      </div>
    </>
  ) : null;
}

Modal.defaultProps = defaultProps;
