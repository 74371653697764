import React from 'react';
import { marketingEmailSignupId } from '@constants/marketing';
import withPromoLogic, { PromoLogicProps } from './withPromoLogic';

function CaptureEmailNavMenuItem({ handleClick }: PromoLogicProps) {
  return (
    <button
      className={`text-xl text-left font-rp-pn-semi-bold cursor-pointer hover:underline bg-savings-gradient text-transparent bg-clip-text ${marketingEmailSignupId}`}
      type="button"
      onClick={handleClick}
    >
      Unlock Savings
    </button>
  );
}

export default withPromoLogic(CaptureEmailNavMenuItem, 'CaptureEmailNavMenuItem');
