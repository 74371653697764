import React from 'react';
import Image from 'next/image';
import arrowLeft from '@assets/images/arrow-left-white.svg';
import arrowRight from '@assets/images/arrow-right-white.svg';

export default function ArrowButton({
  direction,
  onClick,
}: {
  direction: 'left' | 'right';
  onClick: () => void;
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      aria-label={`${direction === 'left' ? 'Previous' : 'Next'} Message`}
    >
      <Image
        src={direction === 'left' ? arrowLeft : arrowRight}
        alt={`${direction === 'left' ? 'Previous' : 'Next'} Message`}
        width={16}
        height={16}
      />
    </button>
  );
}
