/* eslint-disable no-nested-ternary */
import React, { ComponentType } from 'react';
import { useEvents } from '@events/EventsProvider';
import { CAPTURE_EMAIL_CLICKED } from '@constants/amplitudeEvents';
import useEmailCaptureConfig, { PromoConfig } from '@hooks/useEmailCaptureConfig';
import { BFCMPromoFlag, ComponentMappings } from '@customTypes/promos';
import { useRouter } from 'next/router';

export type PromoLogicProps = {
  bfCMPromoFlag: BFCMPromoFlag;
  promoConfig: PromoConfig;
  handleClick: () => void;
};

export default function withPromoLogic<T extends object>(
  WrappedComponent: ComponentType<T & PromoLogicProps>,
  source: keyof ComponentMappings | 'MarketingModule',
) {
  return function ComponentWithPromoLogic(props: T) {
    const router = useRouter();

    const componentName =
      source === 'MarketingModule'
        ? router.pathname === '/'
          ? 'HomePageMarketingModule'
          : 'SRPMarketingModule'
        : source;

    const { bfCMPromoFlag, promoConfig } = useEmailCaptureConfig({
      componentName,
    });

    const { track } = useEvents();

    const handleClick = () => {
      track(CAPTURE_EMAIL_CLICKED, { source: componentName });
    };

    return (
      <WrappedComponent
        {...props}
        bfCMPromoFlag={bfCMPromoFlag}
        promoConfig={promoConfig}
        handleClick={handleClick}
      />
    );
  };
}
