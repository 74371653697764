/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RoutingPath from '@constants/routingPath';
import { useRouter } from 'next/router';
import useIsAndroid from '@hooks/useIsAndroid';
import { SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED } from '@constants/amplitudeEvents';
import { useEvents } from '@events/EventsProvider';
import { marketingEmailSignupId } from '@constants/marketing';
import ArrowButton from './ArrowButton';
import BannerMessage from './BannerMessage';
import withPromoLogic, { PromoLogicProps } from '../withPromoLogic';

function SkinnyBanner({ bfCMPromoFlag, handleClick, promoConfig }: PromoLogicProps) {
  const router = useRouter();
  const path = router.pathname;
  const isAnAndroidDevice = useIsAndroid();
  const { track } = useEvents();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const banners = useMemo(
    () => [
      {
        message: promoConfig?.promo_text || '',
        cta: {
          text: promoConfig?.promo_text_cta_label,
          link: '#',
          id: marketingEmailSignupId,
        },
      },
      {
        message: 'Get the ResortPass App',
        cta: {
          text: 'Download',
          link: isAnAndroidDevice
            ? 'https://play.google.com/store/apps/details?id=com.resortpass.app'
            : 'https://itunes.apple.com/app/id1557539492',
          id: 'app-download',
        },
      },
    ],
    [isAnAndroidDevice, promoConfig],
  );

  const { message, cta } = banners[currentIndex];

  const handleNext = useCallback(() => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % banners.length);
      setIsFading(false);
    }, 500);
  }, [banners.length]);

  const handlePrevious = useCallback(() => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex((prev) => (prev - 1 + banners.length) % banners.length);
      setIsFading(false);
    }, 500);
  }, [banners.length]);

  const handleCTAClick = () => {
    if (cta.id === marketingEmailSignupId) {
      handleClick();
    } else if (cta.id === 'app-download') {
      track(SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED, {});
      window.open(cta.link, '_blank');
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (!isHovered) {
      intervalId = setInterval(handleNext, 7000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isHovered, handleNext]);

  useEffect(() => {
    if (bfCMPromoFlag) {
      setIsLoading(false);
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeoutId);
    };
  }, [bfCMPromoFlag]);

  const backgroundClass =
    bfCMPromoFlag === 'early-access' || bfCMPromoFlag === 'main-promo'
      ? 'bg-rp-gold-2'
      : 'bg-rp-shadow';

  return !isLoading &&
    path !== RoutingPath.GUEST_CHECKOUT &&
    path !== RoutingPath.GUEST_BILLING_DETAILS ? (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`${backgroundClass} flex h-10 items-center justify-between w-full px-2 d:px-18px`}
    >
      <ArrowButton direction="left" onClick={handlePrevious} />

      <BannerMessage isFading={isFading} message={message} cta={cta} onClick={handleCTAClick} />

      <ArrowButton direction="right" onClick={handleNext} />
    </div>
  ) : null;
}

export default withPromoLogic(SkinnyBanner, 'SkinnyBanner');
